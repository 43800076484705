<template>
    <div class="container">
        <div class="section-plan-function">
            <p class="title-border title-plan-function">{{ $t('servicePlanFunction.planHouseTitle')}}</p>
            <p class="detailPlan">{{ $t('servicePlanFunction.detailPlan1')}} <br><br> {{ $t('servicePlanFunction.detailPlan2')}} <br><br> {{ $t('servicePlanFunction.detailPlan3')}} <br><br> {{ $t('servicePlanFunction.detailPlan4')}}</p>
            <blockquote>
				<p><em>{{ $t('servicePlanFunction.bq')}}</em></p>
				<small>by <cite>ASSET UP</cite></small>
			</blockquote>
            <p class="detailPlan">{{ $t('servicePlanFunction.detailPlan5')}} <br><br> {{ $t('servicePlanFunction.detailPlan6')}}</p>
            <div class="row">
                <div class="col-sm-6">
                    <p class="title-assetupService">{{ $t('servicePlanFunction.duties')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailDuties1')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailDuties2')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailDuties3')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailDuties4')}}</p>
                </div>
                <div class="col-sm-6">
                    <p class="title-assetupService">{{ $t('servicePlanFunction.adv')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailAdv1')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailAdv2')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailAdv3')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailAdv4')}}</p>
                    <p class="detailService">{{ $t('servicePlanFunction.detailAdv5')}}</p>
                </div>    
            </div> 
            
            <div>
                <p class="priceRatePlan">{{ $t('servicePlanFunction.priceRate')}}</p>
                <div class="detailPriceRatePlan">
                    <p>{{ $t('servicePlanFunction.priceRate1')}}</p>
                    <p>{{ $t('servicePlanFunction.priceRate2')}}</p>
                    <p>{{ $t('servicePlanFunction.priceRate3')}}</p>
                    <p>{{ $t('servicePlanFunction.vat')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>